import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface IScrollToTopProps extends RouteComponentProps {}

class ScrollToTop extends React.Component<IScrollToTopProps> {
  componentDidUpdate(prevProps: IScrollToTopProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
