import React from 'react';
import moment from 'moment';
import temperaturePlaceholder from '../img/temperature-placeholder.png';
import MapLink from './helpers/MapLink';
import { ILocation } from '../interfaces';

function hasValidReadingTime(lastReadingTime: string) {
  if (!lastReadingTime) return false;
  if (moment(lastReadingTime).isBefore(moment().subtract(24, 'hours'))) return false;

  return true;
}

interface ILocationProps {
  location: ILocation;
}

function Location(props: ILocationProps) {
  const imageStyle = {
    background: `url(${
      props.location.PictureURL ? props.location.PictureURL : temperaturePlaceholder
    }) center center no-repeat`,
    backgroundSize: 'cover',
  };

  return (
    <div className='Grid-cell'>
      <div className='location'>
        <div className='image' style={imageStyle}></div>
        <div className='caption'>
          <h2 className='title'>{props.location.Name}</h2>
          <div className='temperature'>
            {hasValidReadingTime(props.location.lastReadingTime) ? props.location.lastTemperature + ' °C' : '-'}{' '}
          </div>
          <div className='last-reading'>
            {hasValidReadingTime(props.location.lastReadingTime)
              ? 'Sist målt: ' + moment(props.location.lastReadingTime).format('DD.MM.YYYY [kl.] HH:mm')
              : 'Måling kommer snart...'}
          </div>
          <MapLink lat={props.location.GPSLat} long={props.location.GPSLong} />
        </div>
      </div>
    </div>
  );
}

export default Location;
