import React from 'react';
import logo from '../img/bokslogo_RGB.svg';
import { Link, RouteComponentProps } from 'react-router-dom';
import Location from './Location';
import Footer from './Footer';
import Loader from './helpers/Loader';
import { IArea } from '../interfaces';

interface IMatchParams {
  name: string;
}

interface IAreaProps extends RouteComponentProps<IMatchParams> {
  areas?: IArea[];
}

function Area(props: IAreaProps) {
  const { params } = props.match;
  const area = props.areas!.find((area) => {
    return area.Name === params.name;
  });

  if (area) {
    return (
      <div className='area'>
        <div className='content'>
          <header className='header'>
            <Link to='/'>
              <button className='back-button'>
                <i className='back-icon'></i> <div className='text'>Tilbake</div>
              </button>
            </Link>
            <Link to='/'>
              <img className='header-logo' src={logo} alt='altibox-logo'></img>
            </Link>
          </header>
          <div className='main-content'>
            <h2 className='title'>
              Badetemperaturer i {area.Name}
              <small>sponses av</small>
            </h2>
            <img src={area.Logo} className='partner-logo' alt={area.PartnerName ? area.PartnerName : 'Partner Logo'} />

            <div className='location-list Grid Grid-center Grid--cols-3'>
              {area.locations.map((location) => {
                return <Location key={location.id} location={location} />;
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return <Loader />;
  }
}

export default Area;
