import React from 'react';
import mapIcon from '../../img/map-marker-alt.svg';

interface IMapLinkProps {
  lat: string;
  long: string;
}

function MapLink(props: IMapLinkProps) {
  const googleMapsLink = `https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${props.lat},${props.long}`;

  if (props.lat && props.long) {
    return (
      <a className='maps-link' target='_blank' rel='noopener noreferrer' href={googleMapsLink}>
        <img src={mapIcon} alt='Map icon' />
        <span>Vis i kart</span>
      </a>
    );
  } else {
    return (
      <div className='maps-link no-coords'>
        <img src={mapIcon} alt='Map icon' />
        <span>Ingen GPS koordinater...</span>
      </div>
    );
  }
}

export default MapLink;
