import React from 'react';
import { Link } from 'react-router-dom';
import { IArea, ILocation } from '../interfaces';

interface IAreaListItemProps {
  area: IArea;
}

function getHottestLocation(locations: ILocation[]): ILocation | undefined {
  if (!locations) return;

  let location: ILocation = locations[0];

  locations.forEach((item) => {
    if (!location && item.lastTemperature) location = item;
    if (!item.lastTemperature) return;

    if (item.lastTemperature > location.lastTemperature || (item.lastTemperature && !location.lastTemperature))
      location = item;
  });

  return location;
}

function AreaListItem(props: IAreaListItemProps) {
  const hottestLocation: ILocation | undefined = getHottestLocation(props.area.locations);
  return (
    <div className='Grid-cell'>
      <Link to={'/areas/' + props.area.Name} className='area-list-item'>
        <div className='title'>
          {props.area.Name} ({props.area.locations.length | 0})
        </div>
        <div className='description'>
          Varmest: <strong>{hottestLocation ? hottestLocation.Name + ', ' : '-'}</strong>
          <strong className='temperature'>
            {hottestLocation && hottestLocation.lastTemperature ? `${hottestLocation.lastTemperature} °C` : '-'}
          </strong>
        </div>
        <div className='icon-right'></div>
      </Link>
    </div>
  );
}

export default AreaListItem;
