import React from 'react';
import logo from '../img/bokslogo_RGB.svg';
import searchIcon from '../img/search-icon.svg';
import AreaListItem from './AreaListItem';
import Footer from './Footer';
import Select, { components } from 'react-select';
import Loader from './helpers/Loader';
import { withRouter } from 'react-router-dom';
import { IArea, IMappedLocation, IPartner } from '../interfaces';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { NoticeProps } from 'react-select/src/components/Menu';

interface IHomeProps {
  areas?: IArea[];
  locations?: IMappedLocation[];
  partners?: IPartner[];
  selectedRegion: string;
  loading: boolean;
  error?: string;
  handleRegionSelect: (e: React.MouseEvent, region: string) => void;
}

function Home(props: IHomeProps) {
  const filteredAreas = props.areas
    ? props.areas.filter((area) => {
        return area.Region.toLowerCase() === props.selectedRegion.toLowerCase();
      })
    : [];

  const groupedLocations = props.areas
    ? props.areas.map((area) => {
        return {
          label: area.Name,
          options: props.locations ? props.locations.filter((location) => location.areaId === area.id) : [],
        };
      })
    : [];

  const getLocationsCountByRegion = (region: string) => {
    const areasInRegion = props.areas ? props.areas.filter((area) => area.Region === region) : [];
    if (!areasInRegion) return 0;

    let count = 0;
    areasInRegion.forEach((area) => (count += area.locations.length));
    return count;
  };

  const ValueContainer = (props: ValueContainerProps<IMappedLocation>) => {
    return (
      <components.ValueContainer {...props}>
        <img className='search-icon' src={searchIcon} alt='Search Icon' />
        {props.children}
      </components.ValueContainer>
    );
  };

  const NoOptionsMessage = (props: NoticeProps<IMappedLocation>) => {
    return <components.NoOptionsMessage {...props}>Ingen badeplasser eller kommuner</components.NoOptionsMessage>;
  };

  const SearchComponent = withRouter(({ history }) => (
    <Select
      className='react-select-container'
      classNamePrefix='react-select'
      placeholder='Søk etter badeplass/kommune'
      isClearable={true}
      isMulti={false}
      blurInputOnSelect={true}
      options={groupedLocations}
      onChange={(selectedOption: any) => {
        if (selectedOption) history.push('/areas/' + selectedOption.value);
      }}
      components={{ ValueContainer, NoOptionsMessage }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#6E6564',
          primary25: '#eee',
          primary50: '#ddd',
        },
      })}
    />
  ));

  return (
    <div className='home'>
      <div className='content'>
        <header className='header'>
          <img className='header-logo' src={logo} alt='altibox-logo'></img>
          <h2 className='title'>
            Sjekk badetemperaturen
            <small>Her kommer badetemperaturer fra hele Norge</small>
          </h2>

          <div className='search'>
            <SearchComponent />
          </div>
        </header>
        <div className='main-content'>
          <div className='region-filter'>
            <h4 className='title'>Utforsk badeplasser</h4>
            <ul>
              <li>
                <a
                  href='#vestlandet'
                  className={
                    'region' + (props.selectedRegion.toLowerCase() === 'Vestlandet'.toLowerCase() ? ' active' : '')
                  }
                  onClick={(e) => props.handleRegionSelect(e, 'Vestlandet')}
                >
                  Vestlandet ({getLocationsCountByRegion('Vestlandet')})
                </a>
              </li>
              <li>
                <a
                  href='#østlandet'
                  className={
                    'region' + (props.selectedRegion.toLowerCase() === 'Østlandet'.toLowerCase() ? ' active' : '')
                  }
                  onClick={(e) => props.handleRegionSelect(e, 'Østlandet')}
                >
                  Østlandet ({getLocationsCountByRegion('Østlandet')})
                </a>
              </li>
              <li>
                <a
                  href='#sørlandet'
                  className={
                    'region' + (props.selectedRegion.toLowerCase() === 'Sørlandet'.toLowerCase() ? ' active' : '')
                  }
                  onClick={(e) => props.handleRegionSelect(e, 'Sørlandet')}
                >
                  Sørlandet ({getLocationsCountByRegion('Sørlandet')})
                </a>
              </li>
              <li>
                <a
                  href='#midt-norge'
                  className={
                    'region' + (props.selectedRegion.toLowerCase() === 'Midt-Norge'.toLowerCase() ? ' active' : '')
                  }
                  onClick={(e) => props.handleRegionSelect(e, 'Midt-Norge')}
                >
                  Midt-Norge ({getLocationsCountByRegion('Midt-Norge')})
                </a>
              </li>
              <li>
                <a
                  href='#nord-norge'
                  className={
                    'region' + (props.selectedRegion.toLowerCase() === 'Nord-Norge'.toLowerCase() ? ' active' : '')
                  }
                  onClick={(e) => props.handleRegionSelect(e, 'Nord-Norge')}
                >
                  Nord-Norge ({getLocationsCountByRegion('Nord-Norge')})
                </a>
              </li>
            </ul>
          </div>

          <div className='area-list Grid Grid-center Grid--cols-3'>
            {filteredAreas.map((area) => {
              return <AreaListItem key={area.id} area={area} />;
            })}
            {props.loading && <Loader />}
            {props.error && (
              <p className='error'>
                {props.error}
                <br />
                <a href='/'>Prøv igjen</a>
              </p>
            )}
            {filteredAreas.length < 1 && props.areas && props.areas.length > 0 && (
              <p style={{ marginLeft: '15px', fontFamily: 'Roboto' }}>
                Her kommer det flere badetemperaturer snart. Følg med!
              </p>
            )}
          </div>
        </div>
      </div>
      <Footer partners={props.partners} />
    </div>
  );
}

export default Home;
