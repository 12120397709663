import React from 'react';
import { IPartner } from '../interfaces';

interface IFooterProps {
  partners?: IPartner[];
}

function Footer(props: IFooterProps) {
  return (
    <footer className='footer'>
      {props.partners && (
        <div className='main-content'>
          <div className='title'>Sponset av</div>
          <div className='Grid Grid-center Grid--cols-5'>
            {props.partners.map((partner) => {
              return (
                <div key={partner.id} className='partner-logo Grid-cell'>
                  <img src={partner.FooterLogo} alt={partner.Name}></img>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className='info'>
        <p className='contact'>Kontakt: badetassen@altibox.no</p>
        <a
          href='https://www.altibox.no/privat/kundeservice/personvern/personvernerklaering/'
          target='_blank'
          rel='noopener noreferrer'
          className='privacy'
        >
          Personvern i altibox
        </a>
        <p className='copyright'>© 2019 altibox</p>
      </div>
    </footer>
  );
}

export default Footer;
