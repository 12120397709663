import React from 'react';
import LoaderSvg from '../../img/oval.svg';

function Loader() {
  return (
    <div className='loader'>
      <img src={LoaderSvg} alt='Loader'></img>
    </div>
  );
}

export default Loader;
