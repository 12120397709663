import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './Home';
import Area from './Area';
import ScrollToTop from './helpers/ScrollToTop';
import { IArea, IPartner, ILocation, IMappedLocation } from '../interfaces';
import moment from 'moment';

import '../style/App.scss';

interface IAppState {
  areas?: IArea[];
  partners?: IPartner[];
  locations?: IMappedLocation[];
  selectedRegion: string;
  loading: boolean;
  error?: string;
}

const ascending = (a: string, b: string) => (a > b ? 1 : a < b ? -1 : 0);

class App extends React.Component<{}, IAppState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      areas: [],
      partners: [],
      locations: [],
      selectedRegion: decodeURI(window.location.hash.replace('#', '')) || 'Vestlandet',
      loading: true,
    };

    this.handleRegionSelect = this.handleRegionSelect.bind(this);
  }

  componentDidMount() {
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
    const authToken = process.env.REACT_APP_AUTH_TOKEN;

    Promise.all(
      ['/area/', '/location/', '/partner/'].map((url) =>
        fetch(baseApiUrl + url, { headers: { Authorization: 'Bearer ' + authToken } }).then((response) =>
          response.json(),
        ),
      ),
    )
      .then((values) => {
        const mappedLocations: IMappedLocation[] = [];
        const areas: IArea[] = values[0];
        const locations: ILocation[] = values[1];
        const partners: IPartner[] = values[2];

        areas.sort((a, b) => ascending(a.Name.toLowerCase(), b.Name.toLowerCase()));
        locations.sort((a, b) => ascending(a.Name.toLowerCase(), b.Name.toLowerCase()));

        areas.forEach((area) => {
          const partner = partners.find((partner) => partner.id === area.Partner_id);
          area.locations = [];

          if (partner) {
            area.Logo = partner.Logo;
            area.PartnerName = partner.Name;
          }
        });

        locations.forEach((location) => {
          const area = areas.find((area) => location.Area_id === area.id);

          if (location.lastTemperature && typeof location.lastTemperature === 'string')
            location.lastTemperature = parseFloat(location.lastTemperature);

          if (moment(location.lastReadingTime).isBefore(moment().subtract(24, 'hours'))) {
            delete location.lastTemperature;
            delete location.lastReadingTime;
          }

          mappedLocations.push({
            value: area!.Name,
            label: location.Name,
            areaId: location.Area_id,
          });

          area!.locations.push(location);
        });

        this.setState({ areas: areas, partners: partners, locations: mappedLocations, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false, error: 'Feil ved henting av data fra api' });
      });
  }

  handleRegionSelect(e: React.MouseEvent, region: string) {
    if (region.toLowerCase() === this.state.selectedRegion.toLowerCase()) return;
    this.setState({ selectedRegion: region });
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <Route
            exact
            path='/'
            render={(routeProps) => (
              <Home
                {...routeProps}
                areas={this.state.areas}
                locations={this.state.locations}
                selectedRegion={this.state.selectedRegion}
                handleRegionSelect={this.handleRegionSelect}
                loading={this.state.loading}
                error={this.state.error}
                partners={this.state.partners}
              />
            )}
          />
          <Route path='/areas/:name' render={(routeProps) => <Area {...routeProps} areas={this.state.areas} />} />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
